import { IStrapiCollection, IStrapiEntity } from '@core/interfaces/strapi.interface';
import { TStrapiTag } from '@core/types/strapi.type';
import { ISimple } from '@noda-lib/utils/interfaces';
import { IArticle } from '@pages/articles/interfaces/article.interface';
import { DEFAULT_LANG } from '@translate/constants/available-langs';

export function extractTagsCollection(
  articles: IStrapiCollection<IArticle>,
): ISimple<TStrapiTag[]> {
  const tagsByLocale: ISimple<Map<number, IStrapiEntity<TStrapiTag>>> = {};

  articles.data.forEach((article: IStrapiEntity<IArticle>) => {
    const locale: string = article.attributes.language?.data?.attributes.value || DEFAULT_LANG;
    if (!tagsByLocale[locale]) {
      tagsByLocale[locale] = new Map<number, IStrapiEntity<TStrapiTag>>();
    }
    if (article.attributes.preview?.tags?.data) {
      article.attributes.preview.tags.data.forEach((tag: IStrapiEntity<TStrapiTag>) => {
        if (!tagsByLocale[locale].has(tag.id)) {
          tagsByLocale[locale].set(tag.id, tag);
        }
      });
    }
  });

  const result: ISimple<TStrapiTag[]> = {};

  Object.keys(tagsByLocale).forEach(locale => {
    result[locale] = Array.from(tagsByLocale[locale].values()).map(
      (tag: IStrapiEntity<TStrapiTag>) => tag.attributes,
    );
  });

  return result;
}
