import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { IStrapiCollection } from '@core/interfaces/strapi.interface';
import { EnvMergeService } from '@core/services/env-merge.service';
import { RoutingService } from '@core/services/routing.service';
import { hasRouteSegment, hasSegmentsAfterArticles } from '@pages/articles/utils/has-route-segment';
import { DEFAULT_LANG } from '@translate/constants/available-langs';
import { LanguageService } from '@translate/services/language.service';
import { Observable, switchMap } from 'rxjs';

import { IArticle } from '../interfaces/article.interface';
import { ArticlesDataService } from '../services/articles.data.service';

const createArticlesResolver = (
  options: { includeTags?: boolean } = {},
): ResolveFn<IStrapiCollection<IArticle>> => {
  return (route: ActivatedRouteSnapshot): Observable<IStrapiCollection<IArticle>> => {
    const articlesDataService: ArticlesDataService = inject(ArticlesDataService);
    const languageService: LanguageService = inject(LanguageService);
    const mergeEnvService: EnvMergeService = inject(EnvMergeService);
    const routingService: RoutingService = inject(RoutingService);
    const tag: string = route.queryParams['tag'];

    return mergeEnvService.mergedEnv$.pipe(
      switchMap((): Observable<IStrapiCollection<IArticle>> => {
        const lang: string = languageService.activeLang;

        // working then, user return from a single article
        if (hasSegmentsAfterArticles(routingService.getCurrentRoute())) {
          if (articlesDataService.langNeedDefaultTags() === lang) {
            return articlesDataService.getArticles({
              lang,
              fallbackToDefaultLang: false,
              tagForDefaultLang: tag,
            });
          }

          return articlesDataService.getArticles({ lang, tag });
        }

        // working then, user change language on article page
        if (hasRouteSegment(routingService.getCurrentRoute(), 'articles')) {
          return articlesDataService.getArticles({ lang });
        }

        if (options.includeTags && tag) {
          if (articlesDataService.langNeedDefaultTags() === lang) {
            return articlesDataService.getArticles({ lang: DEFAULT_LANG, tag });
          }

          return articlesDataService.getArticles({ lang, tag });
        }

        return articlesDataService.getArticles({ lang });
      }),
    );
  };
};

export const articlesResolver: ResolveFn<IStrapiCollection<IArticle>> = createArticlesResolver({
  includeTags: true,
});
export const footerArticlesResolver: ResolveFn<IStrapiCollection<IArticle>> =
  createArticlesResolver();
