import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivateFn, NavigationEnd, Router } from '@angular/router';
import { removeParam } from '@pages/articles/utils/remove-param';
import { filter, take } from 'rxjs/operators';

export const clearTagParamsGuard: CanDeactivateFn<never> = (
  _: never,
  currentRoute: ActivatedRouteSnapshot,
): boolean => {
  const router: Router = inject(Router);

  if (currentRoute.queryParams['tag']) {
    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe(() => {
        void router.navigate([router.url.split('?')[0]], {
          queryParams: removeParam(currentRoute.queryParams, 'tag'),
          replaceUrl: true,
        });
      });
  }

  return true;
};
