export function hasRouteSegment(route: string | null | undefined, segment: string): boolean {
  if (!route) {
    return false;
  }

  const routePath: string = route.split('?')[0];
  const segments: string[] = routePath.split('/').filter(s => s.length > 0);

  return segments.includes(segment);
}

export function hasSegmentsAfterArticles(route: string | null | undefined): boolean {
  if (!route) {
    return false;
  }

  const routePath: string = route.split('?')[0];
  const segments: string[] = routePath.split('/').filter(s => s.length > 0);
  const articlesIndex: number = segments.findIndex(segment => segment === 'articles');

  return articlesIndex !== -1 && articlesIndex < segments.length - 1;
}
