import { computed, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { EnItemsPerPage } from '@core/enums/items-per-page.enum';
import { IStrapiCollection, IStrapiEntity } from '@core/interfaces/strapi.interface';
import { EnButtonBreakpoint } from '@core/modules/components/button-v2/button.interface';
import { TStrapiTag } from '@core/types/strapi.type';
import { ISimple } from '@noda-lib/utils/interfaces';
import { ArticlesApiService } from '@pages/articles/services/articles.api.service';
import { extractTagsCollection } from '@pages/articles/utils/extract-tags-collection';
import { TuiBreakpointService } from '@taiga-ui/core';
import { DEFAULT_LANG } from '@translate/constants/available-langs';
import { LanguageService } from '@translate/services/language.service';
import { finalize, Observable, of, switchMap, take } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { IArticle, IGetArticlesParams } from '../interfaces/article.interface';

@Injectable({ providedIn: 'root' })
export class ArticlesDataService {
  protected readonly breakpoint: TuiBreakpointService = inject(TuiBreakpointService);
  protected readonly articleApiService: ArticlesApiService = inject(ArticlesApiService);
  protected readonly languageService: LanguageService = inject(LanguageService);

  public articlesCollection: WritableSignal<ISimple<IArticle[]>> = signal<ISimple<IArticle[]>>({});
  public articlesMap: Signal<Map<string, IArticle>> = computed(() => {
    const newMap = new Map<string, IArticle>();
    Object.values(this.articlesCollection())
      .flat()
      .forEach(article => {
        newMap.set(article.path, article);
      });

    return newMap;
  });

  public tags: WritableSignal<ISimple<TStrapiTag[]>> = signal({ en: [] });

  public article: WritableSignal<IArticle> = signal<IArticle>(null);
  public articlesTotal: WritableSignal<number> = signal<number>(0);
  public articleHero: WritableSignal<IArticle> = signal<IArticle>(null);
  public pageSize: Signal<number> = toSignal(
    this.breakpoint.pipe(
      map(value =>
        EnButtonBreakpoint.mobile === value ? EnItemsPerPage.MOBILE : EnItemsPerPage.DESKTOP,
      ),
    ),
    { initialValue: EnItemsPerPage.DESKTOP },
  );
  public langNeedDefaultTags: WritableSignal<string | null> = signal<string | null>(null);
  public isLoading: WritableSignal<boolean> = signal<boolean>(false);

  private currentLang: Signal<string> = toSignal(this.languageService.langChanges$, {
    initialValue: this.languageService.activeLang,
  });

  public currentLangTags: Signal<TStrapiTag[]> = computed(() => {
    const tagsCollection: ISimple<TStrapiTag[]> = this.tags();
    const lang: string = this.currentLang();

    if (this.langNeedDefaultTags() === lang) {
      return tagsCollection[DEFAULT_LANG];
    }

    return tagsCollection[lang] || [];
  });

  public currentTagsForNavigation: Signal<TStrapiTag[]> = computed(() => {
    const tagsCollection: ISimple<TStrapiTag[]> = this.tags();
    const lang: string = this.currentLang();

    return tagsCollection[lang] || [];
  });

  public getArticle(id: string): Observable<IArticle> {
    if (this.articleHero()?.path === id) {
      this.article.set(this.articleHero());

      return of(this.articleHero());
    }

    if (this.articlesMap()?.has(id)) {
      const article: IArticle = this.articlesMap().get(id);
      this.article.set(article);

      return of(article);
    }

    return this.articleApiService.getArticleById(id).pipe(
      map(({ data }: IStrapiCollection<IArticle>) => data[0]?.attributes),
      tap((article: IArticle) => {
        if (article) {
          this.article.set(article);
        }
      }),
    );
  }

  public getArticles(options: {
    lang: string;
    fallbackToDefault?: boolean;
    fallbackToDefaultLang?: boolean;
    tag?: string;
    tagForDefaultLang?: string;
  }): Observable<IStrapiCollection<IArticle>> {
    const {
      lang,
      tag,
      fallbackToDefaultLang = true,
      fallbackToDefault = true,
      tagForDefaultLang,
    } = options;
    this.isLoading.set(true);

    const queryParams: IGetArticlesParams = {
      pageSize: this.pageSize(),
      lang,
    };

    if (tag) {
      queryParams.tag = tag;
    }

    return this.articleApiService.getArticles(queryParams).pipe(
      switchMap((result: IStrapiCollection<IArticle>) => {
        if (result.data.length > 0) {
          this.processArticlesResult(result);

          return of(result);
        }

        if (tag && fallbackToDefaultLang) {
          return this.getArticles({
            lang,
            fallbackToDefaultLang: false,
            fallbackToDefault,
            tagForDefaultLang: tag,
          });
        }

        if (fallbackToDefault && lang !== DEFAULT_LANG) {
          this.langNeedDefaultTags.set(lang);

          if (tagForDefaultLang) {
            return this.getArticles({
              lang: DEFAULT_LANG,
              tag: tagForDefaultLang,
              fallbackToDefaultLang: true,
              fallbackToDefault: false,
            });
          }

          return this.getArticles({
            lang: DEFAULT_LANG,
            fallbackToDefaultLang: true,
            fallbackToDefault: false,
          });
        }

        return of(result);
      }),
      finalize(() => {
        this.isLoading.set(false);
      }),
    );
  }

  private processArticlesResult(result: IStrapiCollection<IArticle>): void {
    const {
      data,
      meta: { pagination },
    } = result;
    const articles: IArticle[] = data.map((x: IStrapiEntity<IArticle>): IArticle => x.attributes);

    this.articlesTotal.set(pagination.total - 1);
    this.articleHero.set(articles[0]);
    this.articlesCollection.set({ '1': articles.slice(1) });
  }

  public getArticlesByPage(pageNumber: number, filterTag: string): Observable<IArticle[]> {
    if (pageNumber in this.articlesCollection()) {
      return of(this.articlesCollection()[pageNumber]);
    }
    this.isLoading.set(true);

    const language: string =
      this.langNeedDefaultTags() === this.currentLang() ? DEFAULT_LANG : this.currentLang();

    return this.articleApiService
      .getArticles({
        pageNumber,
        pageSize: this.pageSize(),
        tag: filterTag,
        lang: language,
      })
      .pipe(
        map((response: IStrapiCollection<IArticle>): IArticle[] => {
          return response.data.map(
            (article: IStrapiEntity<IArticle>): IArticle => article.attributes,
          );
        }),
        tap((articles: IArticle[]): void => {
          this.articlesCollection.set({
            ...this.articlesCollection(),
            ...{ [pageNumber]: articles },
          });
        }),
        finalize(() => {
          this.isLoading.set(false);
        }),
      );
  }

  public fetchTags(): void {
    this.articleApiService
      .getArticlesWithTags()
      .pipe(
        tap((response: IStrapiCollection<IArticle>) => {
          const tags: ISimple<TStrapiTag[]> =
            response.data.length > 0 ? extractTagsCollection(response) : { en: [] };
          this.tags.set(tags);
        }),
        take(1),
      )
      .subscribe();
  }
}
