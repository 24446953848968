import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IStrapiCollection } from '@core/interfaces/strapi.interface';
import { toHttpParams } from '@noda-lib/utils/api';
import { ISimple } from '@noda-lib/utils/interfaces';
import { IArticle, IGetArticlesParams } from '@pages/articles/interfaces/article.interface';
import { Observable } from 'rxjs';

import { ARTICLES_URL } from '../constants/url.constants';

@Injectable({
  providedIn: 'root',
})
export class ArticlesApiService {
  private http: HttpClient = inject(HttpClient);

  private readonly defaultQueryParams: HttpParams = toHttpParams({
    'sort[0]': 'publishedAt:desc',
    populate: 'deep,5',
  });

  private getFilterParam(slug: string): ISimple<string> {
    return slug ? { 'filters[preview][tags][value][$eq]': slug } : {};
  }

  private getPaginationParam(pageSize: number, pageNumber: number = 0): ISimple<number> {
    return {
      'pagination[start]': pageNumber ? (pageNumber - 1) * pageSize + 1 : 0,
      'pagination[limit]': pageNumber ? pageSize : pageSize + 1,
    };
  }

  public getArticles({
    pageSize,
    pageNumber = 0,
    tag,
    lang,
  }: IGetArticlesParams): Observable<IStrapiCollection<IArticle>> {
    return this.http.get<IStrapiCollection<IArticle>>(ARTICLES_URL, {
      params: this.defaultQueryParams.appendAll({
        ['filters[language][value][$eq]']: lang,
        ...this.getPaginationParam(pageSize, pageNumber),
        ...this.getFilterParam(tag),
      }),
    });
  }

  public getArticleById(id: string): Observable<IStrapiCollection<IArticle>> {
    const filtrationParams: ISimple<string> = {
      'filters[path][$eq]': id,
    };

    return this.http.get<IStrapiCollection<IArticle>>(ARTICLES_URL, {
      params: this.defaultQueryParams.appendAll(filtrationParams),
    });
  }

  public getArticlesWithTags(): Observable<IStrapiCollection<IArticle>> {
    const articlesWithTagsParams: HttpParams = toHttpParams({
      'populate[preview][populate][tags]': 'true',
      'populate[language]': 'true',
      'filters[$or][0][preview][tags][id][$notNull]': 'true',
      'pagination[pageSize]': '1000',
      'pagination[page]': '1',
    });

    return this.http.get<IStrapiCollection<IArticle>>(ARTICLES_URL, {
      params: articlesWithTagsParams,
    });
  }
}
