import { Injectable } from '@angular/core';
import { IEnvironment } from '@env/interfaces/environment.interface';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EnvMergeService {
  private mergedEnvSubject: ReplaySubject<IEnvironment> = new ReplaySubject<IEnvironment>(1);
  public mergedEnv$: Observable<IEnvironment> = this.mergedEnvSubject.asObservable();

  public mergeEnvironment(env: IEnvironment): void {
    this.mergedEnvSubject.next(env);
  }
}
